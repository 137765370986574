function App() {
  return (
    <div className="App" style={{margin:20}}>
      1. GENERAL
      <p />
      1.1. When accessing the website https://vocalysd.ai/ ('Website'), you
      enter into a legally binding contract with “VOCALYSD.AI” on the terms
      ('Terms') set out in this document. VOCALYSD.AI is a company incorporated
      in Republic of South Africa, with company number [2015/323262/07], and
      ‘we' or 'us' or 'our' as required by the context.
      <p />
      1.2. These Terms apply to all visitors to the Website, including members
      of the public or legal entities accessing the Website for information
      purposes; members of the public or legal entities submitting
      advertisements, comments, files, images, videos, sounds, business listings
      or information, or any other material or data ('Content'); web search
      engines; and data or information aggregators ('users' or 'you' or 'your').
      <p />
      1.3. These Terms and the other policies posted on the Website (including
      the privacy policy https://vocalysd.ai/privacy_policy constitute the
      complete and exclusive understanding and agreement between you and us and
      govern your use of the Website.
      <p />
      1.4. This understanding and agreement supersedes all prior understandings,
      proposals, agreements, negotiations and discussions between the parties,
      whether written or oral.
      <p />
      1.5. The Terms and the relationship between us are governed by the laws of
      the Republic of South Africa.
      <p />
      1.6. You and we agree to submit to the personal and exclusive jurisdiction
      of the courts in the Republic of South Africa. Our failure to exercise or
      enforce any right or provision of the Terms does not constitute a waiver
      of such right or provision. If any provision of the Terms is found by a
      court of competent jurisdiction to be invalid (because, for example, such
      provision conflicts with the laws of another jurisdiction) or
      inapplicable, the parties still agree that the court should endeavour to
      give effect to the parties' intentions as reflected in the provision.
      <p />
      To comply with the Consumer Protection Act, 2008 (the “CPA”), certain
      portions of these Terms have been written in bold, to draw your attention
      to such provisions, as they:
      <p />
      • limit in some way the risk of liability of VOCALYSD.AI or any other
      person;
      <p />
      • constitute an assumption of risk or liability by you;
      <p />
      • impose an obligation on you to indemnify VOCALYSD.AI or any other
      person;
      <p />
      • constitutes an acknowledgment of a fact by you.
      <p />
      You are required to ensure that before using the Website, you had an
      adequate opportunity to read and understand the implications of the terms
      printed in bold.
      <p />
      By using the Website, you agree to these Terms and it will be deemed that
      you understand its implications and that the terms were written in plain
      and understandable language that you understand. We will not be bound by
      any paragraphs written in blue as these paragraphs only function as an
      explanation of the binding clauses directly above these paragraphs.
      <p />
      2. ACCEPTANCE
      <p />
      2.1. By accessing the Website (as amended and updated from time to time),
      you agree to be bound by these Terms. Should you object to any term or
      condition of these Terms, to any guideline or any later changes to it, or
      become dissatisfied with us or the Website please refrain from using the
      Website and contact us using the contact details provided in paragraph 16.
      below.
      <p />
      2.2. These Terms may be updated by us at any time and at our sole
      discretion. We will send you notice of changes to the Website or the Terms
      by email.
      <p />
      3. DATA PROTECTION
      <p />
      3.1. We collect personal information from you. We will handle the
      collection, processing and storage of your personal information in
      accordance with our privacy policy https://vocalysd.ai/privacy_policy.
      <p />
      3.2. By disclosing or submitting your personal information to us, you
      consent to our collecting, processing and storing it for the purposes
      described in our privacy policy https://vocalysd.ai/privacy_policy.
      <p />
      4. ACCESSING (BROWSING) OUR WEBSITE
      <p />
      4.1. The Website and the Content available through the Website may contain
      links to third-party websites ('Third-Party Websites') completely
      unrelated to our Website.
      <p />
      4.2. If you link to Third-Party Websites, you may be subject to the terms
      and other policies of those Third-Party Websites.
      <p />
      4.3. We do not endorse any Content displayed on any Third-Party Website.
      <p />
      4.4. We do not permit copyright-infringing activities or infringement of
      intellectual property rights on the Website. We may, at our sole
      discretion, remove any infringing Content if properly notified that such
      Content infringes another's intellectual property rights.
      <p />
      5. AVAILABILITY
      <p />
      5.1. We will use our reasonable efforts to ensure the Service is available
      at all times.
      <p />
      5.2. However, it is possible that on occasion the Service may be
      unavailable to permit
      <p /> maintenance or other development activity to take place, or in the
      event of force majeure.
      <p />
      5.3. We will use reasonable efforts to publish on the Website advance
      details of any foreseeable unavailability.
      <p />
      5.4. Through web services and APIs, the Service interoperates with a range
      of third-party services.
      <p />
      5.5. We do not make any warranty or representation on the availability of
      those third-party services. Without limiting the previous sentence, if a
      third-party provider stops providing its service or stops making that the
      service available on reasonable terms, we may stop making available our
      Service to you. To avoid doubt, if we exercise our right to cease to
      provide our Service due to a third party not providing its service anymore
      or providing its service on reasonable terms, you will not entitled to any
      refund, discount or other compensation from us.
      <p />
      CPA notice
      <p />
      This nature of this clause is a limitation of liability
      <p />
      The effect of this clause is that you will be unable to recover or claim
      any amount from VOCALYSD.AI
      <p /> if VOCALYSD.AI is no longer in the position to provide a service
      which was dependent on a third-party service provider providing a service
      <p />
      6. INTELLECTUAL PROPERTY RIGHTS
      <p />
      6.1. You acknowledge and agree that the materials on the Website,
      including the text, software, scripts, graphics, images, sounds, music,
      videos, interactive features and the like ('Materials'), and the
      trademarks, service marks and logos contained therein ('Marks'), are owned
      by or licensed to us and are subject to copyright and other intellectual
      property rights.
      <p />
      6.2. We reserve all rights not expressly granted to the Website and the
      Materials. You agree not to use, copy or distribute any of the Materials,
      otherwise than as expressly permitted or to use, copy or distribute the
      Materials of third parties obtained through the Website, for any
      commercial purpose. If you download or print a copy of the Materials for
      personal use, you must retain all copyright and other proprietary notices
      contained therein. You agree not to circumvent, disable or otherwise
      interfere with security-related features of the Website or features that
      prevent or restrict use or copying of any Materials or that enforce
      limitations on the use of the Website or Materials.
      <p />
      6.3. The Website is protected to the maximum extent permitted by copyright
      laws, other laws, and international conventions or treaties. Content
      displayed on or through the Website is protected by copyright as a
      collective work or compilation under copyrights laws, other laws, and
      international conventions or treaties. Any reproduction, modification,
      creation of derivative works from or redistribution of the Website, the
      Materials or the collective work or compilation is expressly prohibited.
      Copying or reproducing the Website, the Materials, or any portion of it,
      to another server or location for further reproduction or redistribution
      is expressly prohibited.
      <p />
      6.4. You also agree not to reproduce, duplicate or copy Content or
      Materials from the Website, and agree to abide by all copyright notices
      and other notices displayed on the Website. You may not decompile or
      disassemble, reverse engineer or otherwise try to discover any source code
      in the Website.
      <p />
      7. INDEMNITY
      <p />
      7.1. You agree to defend, indemnify us and hold us and our officers,
      subsidiaries, affiliates, successors, assigns, directors, officers,
      agents, service providers, suppliers and employees harmless from and
      against any claims, damages, obligations, losses, liabilities, costs or
      debt, and expenses (including attorneys' fees) arising from
      <p />
      7.1.1. your use of and access to the Website;
      <p />
      7.1.2. your violation of any term of these Terms;
      <p />
      7.1.3. your violation of any third-party right, including, without
      limitation, any copyright, trademark, trade secret, or other property or
      privacy right; or
      <p />
      7.1.4. any claim that your content caused damage to a third party.
      <p />
      7.2. This defence and indemnification obligation will survive termination,
      modification or expiration of these Terms and your use of the Website.
      <p />
      CPA notice
      <p />
      The nature of these clauses is a legal indemnity
      <p />
      The effect of these clauses are that if a claim is instituted against
      VOCALYSD.AI due to your use of the Website, then you will pay VOCALYSD.AI
      all amounts that we may have to pay to satisfy the claims instituted
      against us as a result of your use of the Service
      <p />
      8. LIMITATION AND TERMINATION
      <p />
      8.1. You acknowledge and agree that we, in our sole and absolute
      discretion, have the right (but not the obligation) to delete or
      deactivate your account, block your email or IP address, or otherwise
      terminate your access to or use of the Website (or any part of it),
      immediately and without notice, and to remove and discard any content
      within the Website, for any reason including our belief that you have
      violated these Terms.
      <p />
      8.2. You agree that we will not be liable to you or any third party for
      termination of your access to the Website. You also agree not to attempt
      to use the Website after such termination.
      <p />
      CPA notice
      <p />
      The nature of these clauses constitutes an acknowledgement of fact by you
      and a limitation of liability of VOCALYSD.AI
      <p />
      The effect of these clauses is that you will not be in a position at a
      later stage to deny that what is stated in the clauses and you will also
      not be able to institute any claim against VOCALYSD.AI if we exercise our
      rights under these clauses
      <p />
      9. DISCLAIMER OF WARRANTIES
      <p />
      9.1. You expressly acknowledge and agree that use of the Website is at
      your own risk and that the Website is provided on an 'as is' or 'as
      available' basis, with no warranties of any kind.
      <p />
      9.2. All express and implied warranties, including, without limitation,
      the warranties of merchantability, fitness for a particular purpose, and
      non-infringement of proprietary rights, are expressly disclaimed to the
      fullest extent permitted by law. To the fullest extent permitted by law
      we, our officers, directors, employees and agents disclaim all warranties,
      express or implied, in connection with the Website and your use of it. We
      make no warranties or representations about the accuracy or completeness
      of the Website's content, the Website or the content of any Third-Party
      Website linked to the Website and assume no liability or responsibility
      for any -<p />
      9.2.1. errors, mistakes or inaccuracies ;<p />
      9.2.2. unauthorised access to or use of our Service or any personal or
      financial information stored there;
      <p />
      9.2.3. interruption or cessation of transmission to or from the Website;
      <p />
      9.2.4. bugs, viruses, trojan horses or the like which may be transmitted
      to or through the Website by any third party; or
      <p />
      9.2.5. errors or omissions in any content or loss or damage of any kind
      incurred as a result of the use of any content transmitted or otherwise
      made available via the Website.
      <p />
      9.3. We do not warrant, endorse, guarantee or assume responsibility for
      any product or service advertised or offered by a third party through the
      Website or any hyperlinked website featured in any banner or other
      advertising, and we will not be a party to or in any way responsible for
      the monitoring of any transaction between you or other users or
      third-party providers of products or services. As with the purchase of a
      product or service through any medium or in any environment, you should
      use your best judgement and exercise appropriate caution.
      <p />
      CPA notice
      <p />
      The nature of these clauses is an acknowledgement of fact by you
      <p />
      The effect of these clauses is that you will not be in a position at a
      later stage to deny that what is stated in the clauses
      <p />
      10. LIMITATION OF LIABILITY
      <p />
      10.1. In no event will we, our officers, directors, employees or agents be
      liable for direct, indirect, incidental, special, consequential or
      exemplary damages (even if we have been advised of the possibility of such
      damages) resulting from any aspect of your use of the Website, including
      damages arising from the use or misuse of the Website , from the inability
      to use the Website, or from the interruption, suspension, modification,
      alteration, or termination of the Website. These limitations will apply to
      the fullest extent permitted by law.
      <p />
      CPA notice
      <p />
      The nature of this clause is a limitation of liability of VOCALYSD.AI
      <p />
      The effect of this clause is that even if you suffer a loss as a result of
      the use of the Service you will not be in the position to institute a
      claim against VOCALYSD.AI
      <p />
      11. ASSIGNMENT
      <p />
      11.1. These Terms and any rights and licences granted under this agreement
      may not be transferred or assigned by you but may be assigned by us
      without restriction. Any assignment or transfer by you will be void.
      <p />
      12. ABILITY TO ACCEPT TERMS OF USE
      <p />
      12.1. This Website is intended for adults only. You affirm that you are
      more than 18 year of age, or an emancipated minor, or possess legal
      parental or guardian consent, and are fully able and competent to enter
      into the terms, conditions, obligations, affirmations, representations and
      warranties set forth in these Terms and to abide by and comply with these
      Terms.
      <p />
      13. SEVERABILITY
      <p />
      13.1. If any provision of these Terms is held to be invalid, illegal or
      unenforceable, the validity, legality and enforceability of the remaining
      provisions of the Terms will not in any way be affected or impaired.
      <p />
      14. SUCCESSORS AND ASSIGNS
      <p />
      14.1. These Terms will inure to the benefit of and be binding on each
      party's successors and assigns.
      <p />
      15. HOW TO CONTACT US
      <p />
      15.1. If you have questions about these Terms, please contact us at
      [insert contact details].
      <p />
      16. REQUIRED INFORMATION IN TERMS OF SECTION 43 OF THE ELECTRONIC
      COMMUNICATIONS AND TRANSACTIONS
      <p /> ACT, ACT NO. 25 of 20025
      <p />
      16.1. More information about us is provided below:
      <p />
      16.1.1. Full name: VOCALYSD.AI
      <p />
      16.1.2. Main business address of receipt for legal service: 3813 Mara
      Drive, Midrand, Johannesburg, South Africa. 1682
      <p />
      16.1.3. Office-bearers: Kelly Hoffman (CEO)
      <p />
      16.1.4. Membership of any self-regulatory or accreditation bodies: None
      <p />
      16.1.5. The manner in and period within which you can access and maintain
      a full record of any payment transaction: NA
      <p />
      16.1.6. Webmaster: Afrihost
      <p />
      16.1.7. Official website: https://vocalysd.ai/
      <p />
      16.1.8. Email address: info@vocalysd.ai
      <p />
    </div>
  );
}

export default App;
