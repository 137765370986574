function App() {
    
  window.location.href = "https://vocalysd.ai/privacy-policy/";
  return "";/*(
    <div className="App"  style={{margin:20}}>
      1. INTRODUCTION
      <p />
      1.1. We, [●], with registration number [●], are committed to data
      protection and the right to privacy.
      <p />
      1.2. This privacy policy, together with our terms -
      https://vocalysd.ai/terms, sets out the basis on which any personal
      information that we collect from you will be used, stored and processed by
      us.
      <p />
      2. INFORMATION WE COLLECT FROM YOU
      <p />
      Information that is personal information
      <p />
      2.1. When you visit our Website or make use of our services, we collect
      certain information that is not personal information, such as your
      Internet Protocol ('IP') address, operating system, browser type, and
      internet service provider. This type of information does not identify you
      personally.
      <p />
      Personal information
      <p />
      2.2. When you register on our Website, we also collect personal
      information that you provide to us. In particular, we collect and process
      the following information (which may include personal information) about
      you:
      <p />
      2.2.1. Information that you provide by filling in forms on the Website.
      This includes information provided at the time of your registering to use
      the Website, subscribing to our services and completing data field inputs
      when using our services;
      <p />
      2.2.2. Information that we may ask you for when you report a problem with
      our Website or our services ;<p />
      2.2.3. Information that relates to records of correspondence between us;
      <p />
      2.2.4. Information from surveys that you choose to complete for us that we
      use for research purposes;
      <p />
      2.2.5. Details of transactions you carry out through our Website and of
      the fulfilment of any contract you enter into with us; and
      <p />
      2.2.6. Details of your visits to our Website (including traffic data,
      location data, and weblog and other communication data, whether they be
      required for our own billing purposes or otherwise) and the resources that
      you access.
      <p />
      3. STORAGE OF YOUR PERSONAL DATA
      <p />
      3.1. We will do our best to protect your personal information and we will
      use technology that will help us to do this. The transmission of
      information via the internet is not completely secure, however, and we
      cannot guarantee the security of the information you transmit to our
      Website. Transmission of your information to our Website is at your own
      risk. Once we have received your information, we will use strict
      procedures and security features to try prevent unauthorised access.
      <p />
      3.2. When you have chosen or been given a password which enables you to
      access certain parts of our Website, you have to keep that password
      confidential. Please do not share your password with anyone.
      <p />
      3.3. We will keep your personal information for only a reasonable amount
      time, to enable us to use it for the purposes described in this privacy
      policy and in accordance with applicable law.
      <p />
      4. COOKIES AND IP ADDRESSES
      <p />
      4.1. We may use Cookies to manage your sessions and to store preferences
      and tracking information. Cookies may be used whether you register with us
      or not. Cookies are small text files transferred by a web server to your
      hard drive and thereafter stored on your computer. The types of
      information a Cookie collects include the date and time of your visits to
      the Website, your browsing history on the Website only, your preferences,
      and your username.
      <p />
      4.2. Sometimes, our third-party service providers may use Cookies on the
      Website. We cannot control or access Cookies used by third-party service
      providers. This privacy policy covers only Cookies used by us but not any
      Cookies used by third parties.
      <p />
      4.3. Cookies enable us to
      <p />
      4.3.1. estimate the size and usage pattern of our Website's audience;
      <p />
      4.3.2. store information about your preferences, which allows us to
      customise the Website to your individual interests;
      <p />
      4.3.3. speed up your searches; and
      <p />
      4.3.4. recognise you when you return to the Website.
      <p />
      4.4. You can accept or decline the use of Cookies on your computer,
      whether or not you have registered on our Website. Typically, you can
      configure your browser not to accept Cookies. But declining the use of
      Cookies may limit your access to certain features of the Website. For
      example, you may struggle to log in or using certain interactive features
      of the Website.
      <p />
      5. THIRD PARTIES
      <p />
      5.1. The Website may sometimes contain links to Third-Party Websites. If
      you click on the links to Third-Party Websites, you leave our Website. We
      are not responsible for the content of Third-Party Websites or for the
      security of your personal information when you use them. Third-party
      service providers and Third-Party Websites may have their own privacy
      policies governing the storage and retention of personal information. They
      may also collect information that is not personal information, such as
      your IP address, browser specification or operating system.
      <p />
      5.2. This privacy policy does not govern personal information provided to,
      stored on, or used by third-party providers or Third-Party Websites. We
      recommend that when you enter a Third-Party Website you review its privacy
      policy as it relates to how that Website collects, processes, stores and
      protects your personal information.
      <p />
      6. HOW YOUR INFORMATION IS USED AND DISCLOSED
      <p />
      6.1. We may use the information (including personal information) we hold
      about you in these ways:
      <p />
      6.1.1. to ensure that content from our Website is presented in the most
      effective manner for you and for your computer;
      <p />
      6.1.2. in aggregate and de-identified form, for internal business purposes
      such as generating statistics and developing our strategic and marketing
      plans;
      <p />
      6.1.3. to provide you with information, products, or services that you
      request from us or which we feel may interest you, when you have consented
      to be contacted for such purposes;
      <p />
      6.1.4. to allow you to participate in interactive features of our services
      when you choose to do so;
      <p />
      6.1.5. to carry out any contracts that may form between us;
      <p />
      6.1.6. to respond to any queries you make; and
      <p />
      6.1.7. to notify you about changes to our services.
      <p />
      6.2. We may use information provided by you
      <p />
      6.2.1. monitor user activity such as keyword searches or new postings, and
      more effectively manage traffic on our Website;
      <p />
      6.2.2. to provide customer services and create and manage user accounts;
      and
      <p />
      6.2.3. to assist you with technical difficulties.
      <p />
      6.3. Also, we may share with third-party service providers certain
      information, such as your browser capabilities or operating system, that
      we have collected to understand better which advertisements and services
      may interest you.
      <p />
      6.4. We may block users in certain countries from using our Website. We
      may retain certain personal information for as long as required to fulfil
      our business objective, even after your account is terminated.
      <p />
      7. PROTECTING YOUR PERSONAL INFORMATION
      <p />
      7.1. The personal information you submit when you register on our Website
      may be protected by a unique customer password and user ID. You should not
      disclose your password information to anyone and always remember to log
      off when you are using a shared computer.
      <p />
      7.2. You may utilise our Website as an anonymous user, by not registering.
      We have taken certain security precautions to safeguard your personal
      information. But as with most electronic transactions, no method is 100%
      safe. While we strive to use commercially acceptable means to protect the
      personal information you provide, we cannot guarantee its security. For
      that reason, you acknowledge and agree that we assume no liability for the
      theft, loss, alteration or misuse of personal or other information or
      content, including, without limitation, such information as has been
      provided to third parties or other users, or for the failure of a third
      party to abide by the agreement between us and such third party.
      <p />
      7.3. You agree that you are responsible for maintaining the
      confidentiality of your username and password and all uses of your
      account, whether or not you have authorised such use.
      <p />
      8. ACCESSING AND MODIFYING PERSONAL INFORMATION AND COMMUNICATION
      PREFERENCES
      <p />
      8.1. You may access, remove, review, and/or make changes to personal
      information that you provide us with. If you register, we may also send
      you any legally required notifications and certain notifications including
      service-related notices and notices regarding a change to any of our
      policies. For example, we may send you a notice regarding server problems
      or scheduled maintenance to the Website. You can always opt-out of certain
      e-mail communications from us, including our notifications to you.
      <p />
      8.2. We will not change your preferences without your consent.
      <p />
      9. DISCLOSURES
      <p />
      9.1. On occasions, we may be required to disclose your personal
      information because of legal or regulatory requirements. In such
      instances, we reserve the right to disclose your personal information as
      required in order to comply with our legal obligations including complying
      with court orders, warrants, subpoenas, service-of-process requirements or
      discovery requests.
      <p />
      9.2. We may also disclose information about our users to law enforcement
      officers or others, in the good-faith belief that such disclosure is
      reasonably necessary to enforce our terms and conditions or this privacy
      policy or respond to legal claims that any content violates the rights of
      third parties, or to protect our intellectual property rights or our
      personal safety or the personal safety of our users or the general public.
      <p />
      9.3. We will notify you of any transaction in which substantially all of
      our assets are sold to or merged into another company, including assets
      which contain your personal information. The acquiring company will be
      given access to your personal information without your consent.
      <p />
      10. NOTIFICATION OF CHANGES
      <p />
      10.1. We reserve the right to change this privacy policy from time to time
      and in our sole discretion. We will notify you of material changes to this
      privacy policy by posting such notification on our website.
      <p />
      11. HOW TO CONTACT US
      <p />
      11.1. If you have questions about this privacy policy, please contact us
      at [●] with 'Privacy Policy' in the subject line.
      <p />
    </div>
  );*/
}

export default App;
