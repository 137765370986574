// TalkRatioCard.js
import React from "react";
import { TT } from "../../consts.js"; // Adjust path if needed

const TalkRatioCard = ({ data, colours }) => {
  const agentTalkTime = data?.analyticshits?.agenttalktime || 0;
  const clientTalkTime = data?.analyticshits?.clienttalktime || 0;
  const silenceTime = data?.analyticshits?.silence || 0;
  const totalTime = agentTalkTime + clientTalkTime + silenceTime;

  // Calculate percentages with a fallback to 0 if totalTime is 0
  const agentPercentage = totalTime ? ((agentTalkTime / totalTime) * 100).toFixed(0) : 0;
  const clientPercentage = totalTime ? ((clientTalkTime / totalTime) * 100).toFixed(0) : 0;
  const silencePercentage = totalTime ? ((silenceTime / totalTime) * 100).toFixed(0) : 0;

  return (
    <div style={colours.vboxSmall} className="vocalysd-card">
      <div className="vocalysd-heading">
        Talk ratio{" "}
        <TT
          info={
            "The ratio between Agent speaking time, Client speaking time, and silence in a recording."
          }
        />
      </div>
      <div>
        <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
          <div style={{ marginRight: 5 }}>
            <div style={{ fontSize: 30, fontWeight: "600" }}>{agentPercentage}</div>
            <div style={{ fontSize: 12, color: "#999DAC", fontFamily: "Poppins" }}>
              Agent
            </div>
          </div>
          <div style={{ marginRight: 5 }}>
            <div style={{ fontSize: 30, fontWeight: "600" }}>:</div>
          </div>
          <div style={{ marginRight: 5 }}>
            <div style={{ fontSize: 30, fontWeight: "600" }}>{clientPercentage}</div>
            <div style={{ fontSize: 12, color: "#999DAC", fontFamily: "Poppins" }}>
              Client
            </div>
          </div>
          <div style={{ marginRight: 5 }}>
            <div style={{ fontSize: 30, fontWeight: "600" }}>:</div>
          </div>
          <div style={{ marginRight: 5 }}>
            <div style={{ fontSize: 30, fontWeight: "600" }}>{silencePercentage}</div>
            <div style={{ fontSize: 12, color: "#999DAC", fontFamily: "Poppins" }}>
              Silence
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TalkRatioCard;