import React from "react";
import {
  Dialog,
  DialogTitle,
  Box,
  Button,
  LinearProgress,
  Divider,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close"; // Import the close icon
import UploadTabs from "./upload-tab"; // Handles tab selection
import FileDropZone from "./upload-file-drop-zone"; // Handles the dropzone for files
import { Storage } from "aws-amplify"; 

function UploadDialogue({ open, onClose, input, output, campaigndata }) {
  const [uploading, setUploading] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState("Upload Audio");
  const [files, setFiles] = React.useState([]);
  const [uploadProgress, setUploadProgress] = React.useState(0);

  function reset() {
    setUploading(false);
    setFiles([]);
  }

  const handleUpload = async () => {
    if (files.length === 0) return;

    setUploading(true);

    try {
      const filePromises = files.map((file) => {
        return Storage.put(file.name, file, {
          level: "public", // Use the correct access level (e.g., "public", "private")
          contentType: file.type,
          progressCallback(progress) {
            setUploadProgress(Math.round((progress.loaded / progress.total) * 100));
          },
        });
      });

      // Wait for all files to be uploaded
      await Promise.all(filePromises);

      console.log("Upload successful", files);

      reset();
      onClose(); // Close the dialog after successful upload
    } catch (error) {
      console.error("Upload failed", error);
      reset();
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
      >
        Upload Conversation
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <Box sx={{ padding: 2.5 }}>
        <UploadTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        <FileDropZone files={files} setFiles={setFiles} />
        <Box sx={{ marginTop: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpload}
            disabled={uploading}
            sx={{
              backgroundColor: "#df9508",
              "&:hover": {
                backgroundColor: "#c78507", // Optional: Set a slightly darker color for hover
              },
            }}
          >
            {uploading ? "Uploading..." : "Start Upload"}
          </Button>
        </Box>
        {uploading && <LinearProgress sx={{ marginTop: 2 }} />}
      </Box>
    </Dialog>
  );
}

export default UploadDialogue;