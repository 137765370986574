import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { colours } from "../consts.js";
import CircularProgress from "@mui/material/CircularProgress";
// import { useNavigate } from "react-router-dom";
import Utils from "../utils";

function App() {
  const [user, setUser] = React.useState({});
  const [firstname, setFirstname] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [spinner, setSpinner] = React.useState(false);
  async function getCompanyData() {
    Utils.sendProgressBar(true);
    setSpinner(true)
    let user = await Utils.getUserName();
    let userdetails = await Utils.genericGet("getUser", user.username);
    setUser(userdetails.data.getUser);
    setFirstname(userdetails.data.getUser.firstname);
    setLastname(userdetails.data.getUser.lastname);
    setSpinner(false)
  }

  React.useEffect(() => {
    if (!dataLoaded) {
      const fetchData = async () => {
        await getCompanyData(); // Your async data fetching function
        setDataLoaded(true); // Mark data as loaded
      };
      fetchData();
    }

    window.scrollTo(0, 0); // Scroll to top when component mounts

  }, [dataLoaded]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          background: "#f8f9fd",
          minHeight: "100vh",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginLeft: 30,
              marginTop: 10,
            }}
          >
            Profile details
          </div>
          <div style={colours.vbox}>
            <div>
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="First name"
                InputLabelProps={{
                    style: { color: '#84858a' },
                }}
                style={{ marginRight: 16 }}
                value={firstname || ""}
                onChange={(c) => {
                  setFirstname(c.target.value);
                }}
              />
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="Last name"
                InputLabelProps={{
                    style: { color: '#84858a' },
                }}
                value={lastname || ""}
                onChange={(c) => {
                  setLastname(c.target.value);
                }}
              />
            </div>
            <div>
              <Button
                style={{
                  textTransform: "none",
                  float: "left",
                  marginTop: 16,
                  borderRadius: 20,
                  fontWeight: "bold",
                  fontFamily: "Poppins",
                  background: colours.primary100,
                  color: colours.white,
                  border: "1px solid #ddd",
                  boxShadow: "none",
                }}
                variant="contained"
                onClick={async () => {
                  Utils.sendProgressBar(true);
                  await Utils.genericMutation("updateUser", {
                    id: user.id,
                    firstname: firstname,
                    lastname: lastname,
                  });
                  await Utils.refreshUserGroups()
                }}
              >
                Save changes
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
