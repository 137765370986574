import React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

function Vbox({ input, hw, ht, mr }) {

  return (
    <div
      style={{
        width: hw ? "50%" : null,
        // flexShrink: 1,
        height: 400,
        // marginRight: mr ? mr : 0,
        // marginBottom: 20,
        padding: 10,
        paddingLeft: 20,
        margin: 10,
        background: "#F8F9FD",
        boxShadow: "9px 9px 10px #D1D9E6, -9px -9px 10px #FFFFFF",
        borderRadius: "20px",
      }}
    >
      {input}
    </div>
  );
}
function App() {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ width: "80%" }}>
        <Vbox
          input={
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>Profile details</div>
              <div>Role</div>
              <div>Manager</div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <TextField
                  id="outlined-basic"
                    label="First name"
                    InputLabelProps={{
                        style: { color: '#84858a' },
                    }}
                  variant="outlined"
                  style={{background:"#fff"}}
                />
                <TextField
                  id="outlined-basic"
                label="Last name"
                InputLabelProps={{
                    style: { color: '#84858a' },
                }}
                  variant="outlined"
                  style={{background:"#fff"}}
                />
              </div>
              <Button
                style={{ textTransform: "none" }}

              >
                Save changes
              </Button>
              <TextField
                id="outlined-basic"
                label="Email address"
                InputLabelProps={{
                    style: { color: '#84858a' },
                }}
                variant="outlined"
                disabled={true}
                value={"test@test.com"}

              />
              <Button
                style={{ textTransform: "none" }}

              >
                Password reset
              </Button>
              <Button
                style={{ textTransform: "none" }}
                
              >
                Delete my profile
              </Button>
            </div>
          }
        />
      </div>
    </div>
  );
}

export default App;
