import React from "react";
import { Box, Tabs, Tab } from "@mui/material";

const UploadTabs = ({ selectedTab, setSelectedTab }) => {
  return (
    <Box sx={{ marginBottom: 2 }}>
      <Tabs
        value={selectedTab}
        onChange={(e, newValue) => setSelectedTab(newValue)}
        variant="fullWidth"
        sx={{
          "& .MuiTab-root": {
            color: "black", // Default color for unselected tabs
            textTransform: "none", // Optional: Avoid all-uppercase text
            "&:hover": {
              color: "#c78507", // Lighter color on hover
            },
          },
          "& .Mui-selected": {
            color: "#df9508 !important", // Ensure selected tab text uses your desired color
          },
          "& .MuiTabs-indicator": {
            backgroundColor: "#df9508", // Darker color for the selected tab's indicator
          },
        }}
      >
        <Tab label="Upload Audio" value="Upload Audio" />
        <Tab label="Upload CSV" value="Upload CSV" />
        <Tab label="Upload Text" value="Upload Text" />
      </Tabs>
    </Box>
  );
};

export default UploadTabs;