// TopAlertsCard.js
import React from "react";
import Chip from "@mui/material/Chip";
import { TT } from "../../consts.js"; // Adjust path as needed

const TopAlertsCard = ({ data, colours }) => {
  return (
    <div style={colours.vboxSmall} className="vocalysd-card">
      <div className="vocalysd-heading">
        Top alerts
        <TT info={"The Alerts most frequently triggered."} />
      </div>
      <div>
        {!data.alerthits
          ? null
          : data.alerthits.map((z, zx) => (
              <Chip
                label={z.alert}
                key={zx}
                style={{ margin: 1, fontFamily: "Poppins" }}
              />
            ))}
      </div>
    </div>
  );
};

export default TopAlertsCard;