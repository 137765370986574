/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://hoehw4xbbvdvxfox7ovimjqyxq.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-1:b094b686-edfd-4c4d-865a-18d4e331cab4",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_w2YZqAWSb",
    "aws_user_pools_web_client_id": "4appclml1t5auif805pjc7csl1",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "vconsoleb4f66078940749048d8f2ef8d7c6e481163007-production",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
