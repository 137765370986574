import React from "react";
import Box from "@mui/material/Box";
import BorderLinearProgress from "@mui/material/LinearProgress";
import { TT } from "../../consts.js"; 

const VocalysdScore = ({ allscore, colours }) => {
  return (
    <div className="vocalysd-card">
      <div className="vocalysd-heading">
        Overall Vocalysd score
        <TT
          info={
            "The Vocalysd Score is the overall health metric assigned to a Conversation. The higher the percentage value, the better."
          }
        />
      </div>

      <div style={{ width: "100%", marginTop: 20 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "8px",
          }}
        >
          <div style={{ color: colours.subduedgray }}>
            <span style={{ fontSize: 20, color: "#000", fontWeight: "600" }}>
              {allscore.toFixed(0) + "%"}
            </span>{" "}
            Current Score
          </div>
          <div style={{ color: colours.subduedgray, marginRight: "50px" }}>
            <span style={{ fontSize: 20, color: "#000", fontWeight: "600" }}>
              {"90%"}
            </span>{" "}
            Target
          </div>
        </div>
        <Box sx={{ color: colours.primary100 }}>
          <BorderLinearProgress
            variant="determinate"
            color="inherit"
            value={allscore}
          />
        </Box>
      </div>
    </div>
  );
};

export default VocalysdScore;