import React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TT } from "../../consts.js"; // Adjust path if needed

const AgentAnalytics = ({ pickedAgentTOP, setPickedAgentTOP, colours, listofagents }) => {
  return (
    <div className="vocalysd-card">
      {/* Heading and ButtonGroup */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="vocalysd-heading">
          Agent analytics
          <TT info={"Shows the top performing and bottom performing Agents."} />
        </div>
        <ButtonGroup
          variant="contained"
          aria-label="outlined primary button group"
          className="buttons-border-extend"
        >
          <Button
            onClick={() => setPickedAgentTOP(true)}
            style={{
              textTransform: "none",
              background: pickedAgentTOP ? colours.primary100 : colours.white,
              boxShadow: pickedAgentTOP
                ? "none"
                : "inset 3px 3px 3px #D1D9E6, inset -3px -3px 3px #FFFFFF",
              color: pickedAgentTOP ? colours.white : colours.black,
              fontFamily: "Poppins",
            }}
          >
            Top performers
          </Button>
          <Button
            onClick={() => setPickedAgentTOP(false)}
            style={{
              textTransform: "none",
              background: !pickedAgentTOP ? colours.primary100 : colours.white,
              boxShadow: !pickedAgentTOP
                ? "none"
                : "inset 3px 3px 3px #D1D9E6, inset -3px -3px 3px #FFFFFF",
              color: !pickedAgentTOP ? colours.white : colours.black,
              fontFamily: "Poppins",
            }}
          >
            Bottom performers
          </Button>
        </ButtonGroup>
      </div>

      {/* Table to Display List of Agents */}
      <div style={{ marginTop: 16 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Agent</TableCell>
              <TableCell align="right">Avg. Vocalysd score</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!listofagents ? (
              <TableRow />
            ) : (
              listofagents
                .sort((a, b) => {
                  if (pickedAgentTOP) return b.VS - a.VS;
                  else return a.VS - b.VS;
                })
                .slice(0, 7)
                .map((z, zi) => (
                  <TableRow key={zi}>
                    <TableCell
                      style={{
                        paddingTop: 3,
                        paddingBottom: 3,
                        fontFamily: "Poppins",
                      }}
                    >
                      {zi + 1 + ". " + z.agent}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        paddingTop: 3,
                        paddingBottom: 3,
                        fontFamily: "Poppins",
                      }}
                    >
                      {z.VS.toFixed(0)}
                    </TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default AgentAnalytics;