import TextField from "@mui/material/TextField";
import * as React from "react";

const SearchBar = ({ callBack }) => {
    const [innerValue, setInnerValue] = React.useState("");

    const handleSearch = event => {
        setInnerValue(event.target.value)
        callBack(event.target.value)
    }

    return (
        <form className="searchbar">
            <TextField
                className="searchbarInput"
                label="Search"
                value={innerValue}
                onChange={(event) => handleSearch(event)}
            />
        </form>
    )
}

export default SearchBar