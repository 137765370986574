import React from "react";
import {
  Button,
  ButtonGroup,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { TT } from "../../consts.js";
// New TopQuestionsCard Component
const TopQuestionsCard = ({ data, pickedAgent, setPickedAgent, colours }) => {
  return (
    <div className="vocalysd-card">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="vocalysd-heading">
          Top questions
          <TT info={"The top questions asked by Agents and Clients."} />
        </div>
        <ButtonGroup
          variant="contained"
          aria-label="outlined primary button group"
          className="buttons-border-extend"
        >
          <Button
            onClick={() => setPickedAgent(true)}
            style={{
              background: pickedAgent ? colours.primary100 : colours.white,
              boxShadow: pickedAgent
                ? "none"
                : "inset 3px 3px 3px #D1D9E6, inset -3px -3px 3px #FFFFFF",
              color: pickedAgent ? colours.white : colours.black,
              textTransform: "none",
              fontFamily: "Poppins",
            }}
          >
            Agent
          </Button>
          <Button
            onClick={() => setPickedAgent(false)}
            style={{
              background: !pickedAgent ? colours.primary100 : colours.white,
              boxShadow: !pickedAgent
                ? "none"
                : "inset 3px 3px 3px #D1D9E6, inset -3px -3px 3px #FFFFFF",
              color: !pickedAgent ? colours.white : colours.black,
              textTransform: "none",
              fontFamily: "Poppins",
            }}
          >
            Client
          </Button>
        </ButtonGroup>
      </div>
      <div style={{ marginTop: 16 }}>
        <Table>
          <TableBody>
            {!data.questionhits ? (
              <TableRow />
            ) : (
              data.questionhits
                .filter((z) =>
                  pickedAgent
                    ? z.speaker === "Speaker 2"
                    : z.speaker === "Speaker 1"
                )
                .sort((a, b) => b.n - a.n)
                .slice(0, 7)
                .map((z, zi) => (
                  <TableRow key={zi}>
                    <TableCell
                      style={{
                        paddingTop: 3,
                        paddingBottom: 3,
                        fontFamily: "Poppins",
                      }}
                    >
                      {zi + 1 + ". " + z.text}
                    </TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default TopQuestionsCard;
