import React from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TT } from "../../consts.js"; // Adjust path if needed
import { useNavigate } from "react-router-dom";

const PlaylistsCard = ({ pldata, colours }) => {
  const navigate = useNavigate();

  return (
    <div style={colours.vboxMedium} className="vocalysd-card">
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: 16,
            marginBottom: 20,
          }}
          className="vocalysd-heading"
        >
          Playlists
          <TT info={"A quick indication of how many items are categorised in key Playlists."} />
          <ChevronRightIcon
            style={{ color: "#E7A500", cursor: "pointer" }}
            onClick={() => navigate("/playlists")}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ borderLeft: "3px solid #21A500", paddingLeft: 10 }}>
            <div style={{ fontSize: 12, color: "#999DAC" }}>WOW moments</div>
            <div
              style={{
                fontSize: 30,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div style={{ fontSize: 30 }}>
                {!pldata
                  ? null
                  : pldata.filter((item) => item.bookmarks.includes("WOW moments")).length}
              </div>
            </div>
          </div>

          <div
            style={{
              borderLeft: "3px solid #E87522",
              paddingLeft: 10,
              marginLeft: 200,
            }}
          >
            <div style={{ fontSize: 12, color: "#999DAC" }}>High risk</div>
            <div
              style={{
                fontSize: 30,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div style={{ fontSize: 30 }}>
                {!pldata
                  ? null
                  : pldata.filter((item) => item.bookmarks.includes("High risk")).length}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlaylistsCard;