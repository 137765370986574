/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      contact
      email
      status
      usage
      internalname
      groups
      VShits
      trackerhits
      durationhits
      alerthits
      agenthits
      sentimenthits
      topichits
      questionhits
      analyticshits
      alerts {
        items {
          id
          name
          agent
          conversation
          campaign
          groups
          createdAt
          updatedAt
          companyAlertsId
        }
        nextToken
      }
      trackers
      campaigns {
        items {
          id
          name
          startdate
          enddate
          active
          pinned
          VShits
          trackerhits
          durationhits
          alerthits
          agenthits
          sentimenthits
          topichits
          questionhits
          analyticshits
          trackers
          type
          groups
          createdAt
          updatedAt
          companyCampaignsId
        }
        nextToken
      }
      agents {
        items {
          id
          name
          groups
          createdAt
          updatedAt
          companyAgentsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCompanies = /* GraphQL */ `
  query ListCompanies(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        contact
        email
        status
        usage
        internalname
        groups
        VShits
        trackerhits
        durationhits
        alerthits
        agenthits
        sentimenthits
        topichits
        questionhits
        analyticshits
        alerts {
          nextToken
        }
        trackers
        campaigns {
          nextToken
        }
        agents {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      firstname
      lastname
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstname
        lastname
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getAlert = /* GraphQL */ `
  query GetAlert($id: ID!) {
    getAlert(id: $id) {
      id
      name
      agent
      conversation
      campaign
      company {
        id
        name
        contact
        email
        status
        usage
        internalname
        groups
        VShits
        trackerhits
        durationhits
        alerthits
        agenthits
        sentimenthits
        topichits
        questionhits
        analyticshits
        alerts {
          nextToken
        }
        trackers
        campaigns {
          nextToken
        }
        agents {
          nextToken
        }
        createdAt
        updatedAt
      }
      groups
      createdAt
      updatedAt
      companyAlertsId
    }
  }
`;
export const listAlerts = /* GraphQL */ `
  query ListAlerts(
    $filter: ModelAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlerts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        agent
        conversation
        campaign
        company {
          id
          name
          contact
          email
          status
          usage
          internalname
          groups
          VShits
          trackerhits
          durationhits
          alerthits
          agenthits
          sentimenthits
          topichits
          questionhits
          analyticshits
          trackers
          createdAt
          updatedAt
        }
        groups
        createdAt
        updatedAt
        companyAlertsId
      }
      nextToken
    }
  }
`;
export const getAgent = /* GraphQL */ `
  query GetAgent($id: ID!) {
    getAgent(id: $id) {
      id
      name
      company {
        id
        name
        contact
        email
        status
        usage
        internalname
        groups
        VShits
        trackerhits
        durationhits
        alerthits
        agenthits
        sentimenthits
        topichits
        questionhits
        analyticshits
        alerts {
          nextToken
        }
        trackers
        campaigns {
          nextToken
        }
        agents {
          nextToken
        }
        createdAt
        updatedAt
      }
      groups
      campaigns {
        items {
          id
          agentID
          campaignID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      companyAgentsId
    }
  }
`;
export const listAgents = /* GraphQL */ `
  query ListAgents(
    $filter: ModelAgentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        company {
          id
          name
          contact
          email
          status
          usage
          internalname
          groups
          VShits
          trackerhits
          durationhits
          alerthits
          agenthits
          sentimenthits
          topichits
          questionhits
          analyticshits
          trackers
          createdAt
          updatedAt
        }
        groups
        campaigns {
          nextToken
        }
        createdAt
        updatedAt
        companyAgentsId
      }
      nextToken
    }
  }
`;
export const getCampaign = /* GraphQL */ `
  query GetCampaign($id: ID!) {
    getCampaign(id: $id) {
      id
      name
      startdate
      enddate
      active
      pinned
      VShits
      trackerhits
      durationhits
      alerthits
      agenthits
      sentimenthits
      topichits
      questionhits
      analyticshits
      trackers
      type
      company {
        id
        name
        contact
        email
        status
        usage
        internalname
        groups
        VShits
        trackerhits
        durationhits
        alerthits
        agenthits
        sentimenthits
        topichits
        questionhits
        analyticshits
        alerts {
          nextToken
        }
        trackers
        campaigns {
          nextToken
        }
        agents {
          nextToken
        }
        createdAt
        updatedAt
      }
      conversations {
        items {
          id
          name
          agent
          agenttag
          archive
          startdate
          sentiment
          enddate
          bookmarks
          duration
          VS
          symblstatus
          symbljobid
          symblconversationid
          alertcount
          transcript
          topics
          analytics
          questions
          summary
          trackersdetected
          file
          groups
          createdAt
          updatedAt
          campaignConversationsId
        }
        nextToken
      }
      agents {
        items {
          id
          agentID
          campaignID
          createdAt
          updatedAt
        }
        nextToken
      }
      groups
      createdAt
      updatedAt
      companyCampaignsId
    }
  }
`;

export const listCampaigns = /* GraphQL */ `
  query ListCampaigns(
    $filter: ModelCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCampaigns(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        startdate
        enddate
        active
        pinned
        VShits
        trackerhits
        durationhits
        alerthits
        agenthits
        sentimenthits
        topichits
        questionhits
        analyticshits
        trackers
        type
        company {
          id
          name
          contact
          email
          status
          usage
          internalname
          groups
          VShits
          trackerhits
          durationhits
          alerthits
          agenthits
          sentimenthits
          topichits
          questionhits
          analyticshits
          trackers
          createdAt
          updatedAt
        }
        conversations {
          nextToken
        }
        agents {
          nextToken
        }
        groups
        createdAt
        updatedAt
        companyCampaignsId
      }
      nextToken
    }
  }
`;
export const getConversation = /* GraphQL */ `
  query GetConversation($id: ID!) {
    getConversation(id: $id) {
      id
      name
      agent
      agenttag
      archive
      startdate
      sentiment
      enddate
      bookmarks
      duration
      VS
      symblstatus
      symbljobid
      symblconversationid
      alertcount
      transcript
      topics
      analytics
      questions
      summary
      trackersdetected
      file
      campaign {
        id
        name
        startdate
        enddate
        active
        pinned
        VShits
        trackerhits
        durationhits
        alerthits
        agenthits
        sentimenthits
        topichits
        questionhits
        analyticshits
        trackers
        type
        company {
          id
          name
          contact
          email
          status
          usage
          internalname
          groups
          VShits
          trackerhits
          durationhits
          alerthits
          agenthits
          sentimenthits
          topichits
          questionhits
          analyticshits
          trackers
          createdAt
          updatedAt
        }
        conversations {
          nextToken
        }
        agents {
          nextToken
        }
        groups
        createdAt
        updatedAt
        companyCampaignsId
      }
      groups
      createdAt
      updatedAt
      campaignConversationsId
    }
  }
`;
export const listConversations = /* GraphQL */ `
  query ListConversations(
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConversations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        agent
        agenttag
        archive
        startdate
        sentiment
        enddate
        bookmarks
        duration
        VS
        symblstatus
        symbljobid
        symblconversationid
        alertcount
        transcript
        topics
        analytics
        questions
        summary
        trackersdetected
        file
        campaign {
          id
          name
          startdate
          enddate
          active
          pinned
          VShits
          trackerhits
          durationhits
          alerthits
          agenthits
          sentimenthits
          topichits
          questionhits
          analyticshits
          trackers
          type
          groups
          createdAt
          updatedAt
          companyCampaignsId
        }
        groups
        createdAt
        updatedAt
        campaignConversationsId
      }
      nextToken
    }
  }
`;
export const getAgentsandcampaigns = /* GraphQL */ `
  query GetAgentsandcampaigns($id: ID!) {
    getAgentsandcampaigns(id: $id) {
      id
      agentID
      campaignID
      agent {
        id
        name
        company {
          id
          name
          contact
          email
          status
          usage
          internalname
          groups
          VShits
          trackerhits
          durationhits
          alerthits
          agenthits
          sentimenthits
          topichits
          questionhits
          analyticshits
          trackers
          createdAt
          updatedAt
        }
        groups
        campaigns {
          nextToken
        }
        createdAt
        updatedAt
        companyAgentsId
      }
      campaign {
        id
        name
        startdate
        enddate
        active
        pinned
        VShits
        trackerhits
        durationhits
        alerthits
        agenthits
        sentimenthits
        topichits
        questionhits
        analyticshits
        trackers
        type
        company {
          id
          name
          contact
          email
          status
          usage
          internalname
          groups
          VShits
          trackerhits
          durationhits
          alerthits
          agenthits
          sentimenthits
          topichits
          questionhits
          analyticshits
          trackers
          createdAt
          updatedAt
        }
        conversations {
          nextToken
        }
        agents {
          nextToken
        }
        groups
        createdAt
        updatedAt
        companyCampaignsId
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAgentsandcampaigns = /* GraphQL */ `
  query ListAgentsandcampaigns(
    $filter: ModelAgentsandcampaignsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgentsandcampaigns(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        agentID
        campaignID
        agent {
          id
          name
          groups
          createdAt
          updatedAt
          companyAgentsId
        }
        campaign {
          id
          name
          startdate
          enddate
          active
          pinned
          VShits
          trackerhits
          durationhits
          alerthits
          agenthits
          sentimenthits
          topichits
          questionhits
          analyticshits
          trackers
          type
          groups
          createdAt
          updatedAt
          companyCampaignsId
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const conversationBySConversationId = /* GraphQL */ `
  query ConversationBySConversationId(
    $symbljobid: String
    $sortDirection: ModelSortDirection
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    conversationBySConversationId(
      symbljobid: $symbljobid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        agent
        agenttag
        archive
        startdate
        sentiment
        enddate
        bookmarks
        duration
        VS
        symblstatus
        symbljobid
        symblconversationid
        alertcount
        transcript
        topics
        analytics
        questions
        summary
        trackersdetected
        file
        campaign {
          id
          name
          startdate
          enddate
          active
          pinned
          VShits
          trackerhits
          durationhits
          alerthits
          agenthits
          sentimenthits
          topichits
          questionhits
          analyticshits
          trackers
          type
          groups
          createdAt
          updatedAt
          companyCampaignsId
        }
        groups
        createdAt
        updatedAt
        campaignConversationsId
      }
      nextToken
    }
  }
`;
