import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
//import { colours } from "../consts.js";
import { useLocation, useNavigate } from "react-router-dom";
import Utils from "../../utils";

import FeatherIcon from "feather-icons-react";

function App() {
  let navigate = useNavigate();
  const location = useLocation();
  // console.log(location.state);
  const [data, setData] = React.useState([]);
  const [trackerName, setTrackerName] = React.useState("");
  const [companyData, setCompanyData] = React.useState("");
  const [spinner, setSpinner] = React.useState(false);

    function setMainData() {
      Utils.sendProgressBar(true)
      setSpinner(true)
      
    setData(
      location.state.tracker.phrases.map((z) => {
        return { text: z, edit: false };
      })
    );
    setTrackerName(location.state.tracker.name);
    setCompanyData(location.state.company);

    setSpinner(false)
  }
    React.useEffect(() => {
      if(location.state) setMainData();
      window.scrollTo(0, 0);
    }, []);

    function returnToTrackers() {
        navigate("/trackers")
    }

  async function update(data) {
    Utils.sendProgressBar(true);
    // console.log({ data });
    let found = companyData.trackers.find((z) => z.name === trackerName);
    if (found) {
      // console.log({ found });
      found.phrases = data.map((z) => z.text);
      companyData.trackers.forEach((z) => delete z.campaigns);
      await Utils.genericMutation("updateCompany", {
        id: companyData.id,
        trackers: JSON.stringify(companyData.trackers),
      });
      // console.log({ s });
    }
    let batch = [];
    // console.log({ companyData, data });
    companyData.campaigns.items.forEach((z) => {
      found = z.trackers.find((z) => z.name === trackerName);
      if (found) {
        found.phrases = data.map((z) => z.text);
        batch.push({ id: z.id, trackers: JSON.stringify(z.trackers) });
      }
    });
    // console.log({ batch });
    async function updateall(dta) {
      await Utils.genericMutation("updateCampaign", {
        id: dta.id,
        trackers: dta.trackers,
      });
    }
    await Promise.all(batch.map(updateall));
    
  }

  return (
    <div className="vocalysed-home">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          background: "#f8f9fd",
          minHeight: "100vh",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
              >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                      <FeatherIcon
                          icon="award"
                          style={{ marginLeft: 20, marginBottom: 10 }}
                      />
                      <div style={{ marginLeft: 5, cursor: "pointer" }}>
                          <div onClick={() => returnToTrackers()}>Trackers /</div>
                      </div>
                      <FeatherIcon icon="list" style={{ marginLeft: 5, marginBottom: 10 }} />
                      <div className="menu-item-selected">{trackerName}</div>
                  </div>

          <div  className="vocalysd-card vocalysd-table table-no-last">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
                      Word or phrase for {trackerName}
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((z, zidx) => (
                  <TableRow key={zidx} style={{}}>
                    <TableCell>
                      {z.edit ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              fontFamily: "Poppins",
                              flexDirection: "row",
                              display: "flex",
                              width: "100%",
                            }}
                          >
                            <TextField
                              id="outlined-basic"
                              style={{ width: "100%" }}
                              multiline={true}
                              variant="outlined"
                              value={z.text || ""}
                              onChange={(c) => {
                                let d = Object.assign([], data);
                                d[zidx].text = c.target.value;
                                setData(d);
                              }}
                            />
                          </div>
                          <Button
                            style={{ color: "#21A500" }}
                            onClick={() => {
                              let d = Object.assign([], data);
                              d[zidx].edit = !d[zidx].edit;
                              setData(d);
                              update(d);
                            }}
                          >
                            Done
                          </Button>
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              fontFamily: "Poppins",
                              flexDirection: "row",
                              display: "flex",
                            }}
                          >
                            <div style={{ marginRight: 30 }}>
                              {zidx + 1 + "."}
                            </div>
                            <div>{z.text}</div>
                          </div>
                          <div
                            style={{
                              flexDirection: "row",
                              display: "flex",
                            }}
                          >
                            <FeatherIcon
                              icon="edit-2"
                              style={{
                                color: "#aaa",
                                marginLeft: 20,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                let d = Object.assign([], data);
                                d[zidx].edit = !d[zidx].edit;
                                setData(d);
                              }}
                            />
                            <FeatherIcon
                              icon="trash-2"
                              style={{
                                color: "#aaa",
                                marginLeft: 20,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                let d = Object.assign([], data);
                                d.splice(zidx, 1);
                                setData(d);
                                // console.log({ d });
                                update(d);
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <div style={{
                padding: 15,
                paddingLeft: 15,
            }}>
                <Button
                    style={{
                        padding: 0,
                        borderRadius: "20px",
                    }}
                    onClick={() => {
                      let d = Object.assign([], data);
                      d.push({ text: "", edit: true });
                      // console.log(d);
                      setData(d);
                    }}
                >
                  <div
                    className="button-light"
                  >
                    <FeatherIcon icon="plus-circle" style={{ marginRight: 4 }} />
                    Add word or phrase
                  </div>
                </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
