import React from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { TT } from "../../consts.js";



const SentimentChart = ({ sentimentdata, barColors, colours }) => {
  return (
      <div style={colours.vboxMediumTall2} className="vocalysd-card">
        <div className="vocalysd-heading">
          Overall sentiment
          <TT
            info={
              "Sentiment indicates the attitude or tone of the Speaker at a point in the Conversation. It can be positive or negative."
            }
          />
        </div>
        <div style={{ width: "100%", height: 310 }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={sentimentdata}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="name"
                axisLine={{ stroke: "#DEE2F1" }}
                fontFamily={"Poppins"}
              />
              <YAxis
                axisLine={{ stroke: "#DEE2F1" }}
                fontFamily={"Poppins"}
                tickFormatter={(d) => d.toFixed(0) + "%"}
              />
              <Tooltip formatter={(v) => v.toFixed(0) + "%"} />
              <defs>
                {barColors.map((color, index) => (
                  <linearGradient
                    id={`colorUv${index}`}
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="100%"
                    spreadMethod="reflect"
                    key={`colorUv${index}`}
                  >
                    <stop offset="0" stopColor={color[0]} />
                    <stop offset="1" stopColor={color[1]} />
                  </linearGradient>
                ))}
              </defs>

              <Bar
                dataKey="Positive"
                stackId="a"
                fill={colours.green100}
                radius={0}
              >
                {sentimentdata.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={`url(#colorUv0)`} />
                ))}
              </Bar>
              <Bar
                dataKey="Negative"
                stackId="a"
                fill={colours.primary100}
                radius={[10, 10, 0, 0]}
              >
                {sentimentdata.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={`url(#colorUv2)`} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
  );
};

export default SentimentChart;
