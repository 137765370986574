import React from "react";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  ResponsiveContainer,
  LabelList,
  Tooltip
} from "recharts";
import { TT } from "../../consts.js";
import { Padding } from "@mui/icons-material";

const TopicSentimentChart = ({ topicdata, colours }) => {
  return (
    <div style={colours.vboxMediumTall2} className="vocalysd-card">
      <div className="vocalysd-heading">
        Topic based sentiment
        <TT
          info={
            "Topics are words or phrases that come up frequently across Conversations, and carry a Sentiment."
          }
        />
      </div>
      <div style={{ width: "100%", height: 310 }}>
        <ResponsiveContainer width="100%" height="100%">
          <ScatterChart
            width={400}
            height={200}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <XAxis
              type="number"
              dataKey="x"
              axisLine={false}
              hide={true}
              range={[1, 10000]}
              domain={[1, 20]}
              scale="linear"
            />
            <YAxis
              type="number"
              dataKey="y"
              axisLine={false}
              hide={true}
              range={[1, 10000]}
              domain={[1, 20]}
              scale="linear"
            />
            <ZAxis
              type="number"
              dataKey="z"
              range={[1, 10000]}
              domain={[1, 20]}
              scale="linear"
            />

            {/* Negative Scatter */}
            <Scatter
              name="Negative"
              data={topicdata.filter((z) => z.sen === "neg")}
              fill={colours.primary100}
              shape="circle"
              radius={10} // Increase this value to make the balls larger
            >
              <LabelList
                dataKey="keyword"
                fill="#fff"
                style={{
                  fontFamily: "Poppins",
                  fontSize: 10, // Adjust the font size to fit inside the balls
                  textAnchor: "middle", // Center the text horizontally
                  dominantBaseline: "middle", // Center the text vertically
                }}
              />
            </Scatter>

            {/* Positive Scatter */}
            <Scatter
              name="Positive"
              data={topicdata.filter((z) => z.sen === "pos")}
              fill={colours.green100}
              shape="circle"
              radius={10} // Increase this value to make the balls larger
            >
              <LabelList
                dataKey="keyword"
                fill="#fff"
                style={{
                  fontFamily: "Poppins",
                  fontSize: 10, // Adjust the font size to fit inside the balls
                  textAnchor: "middle", // Center the text horizontally
                  dominantBaseline: "middle", // Center the text vertically
                }}
              />
            </Scatter>
          </ScatterChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default TopicSentimentChart;
