import React from "react";
import { TT } from "../../consts.js"; // Adjust path if needed

const ComplianceCard = ({ data, colours }) => {
  // Utility function to calculate the percentage
  const getCompliancePercentage = (trackerType) => {
    if (!data.trackerhits) return 0;
    const trackerData = data.trackerhits.find((z) => z.tracker === trackerType);
    return trackerData ? ((trackerData.hits / data.VShits.n) * 100).toFixed(0) : 0;
  };

  return (
    <div style={colours.vboxSmall} className="vocalysd-card">
      <div className="vocalysd-heading">
        Compliance
        <TT
          info={
            "Compliance is based on a User-set checklist of things the Agent needs to cover in a call. Higher % is better."
          }
        />
      </div>
      <div>
        <div style={{ display: "flex", flexDirection: "row", marginTop: 16 }}>
          <div style={{ marginRight: 50 }}>
            <div style={{ fontSize: 12, color: "#999DAC" }}>Legal</div>
            <div style={{ fontSize: 30, fontWeight: "600" }}>
              {getCompliancePercentage("legal-detect")}%
            </div>
          </div>
          <div style={{ marginRight: 5 }}>
            <div style={{ fontSize: 12, color: "#999DAC" }}>Consent</div>
            <div style={{ fontSize: 30, fontWeight: "600" }}>
              {getCompliancePercentage("consent-detect")}%
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComplianceCard;