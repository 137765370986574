/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      name
      contact
      email
      status
      usage
      internalname
      groups
      VShits
      trackerhits
      durationhits
      alerthits
      agenthits
      sentimenthits
      topichits
      questionhits
      analyticshits
      alerts {
        items {
          id
          name
          agent
          conversation
          campaign
          groups
          createdAt
          updatedAt
          companyAlertsId
        }
        nextToken
      }
      trackers
      campaigns {
        items {
          id
          name
          startdate
          enddate
          active
          pinned
          VShits
          trackerhits
          durationhits
          alerthits
          agenthits
          sentimenthits
          topichits
          questionhits
          analyticshits
          trackers
          type
          groups
          createdAt
          updatedAt
          companyCampaignsId
        }
        nextToken
      }
      agents {
        items {
          id
          name
          groups
          createdAt
          updatedAt
          companyAgentsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      name
      contact
      email
      status
      usage
      internalname
      groups
      VShits
      trackerhits
      durationhits
      alerthits
      agenthits
      sentimenthits
      topichits
      questionhits
      analyticshits
      alerts {
        items {
          id
          name
          agent
          conversation
          campaign
          groups
          createdAt
          updatedAt
          companyAlertsId
        }
        nextToken
      }
      trackers
      campaigns {
        items {
          id
          name
          startdate
          enddate
          active
          pinned
          VShits
          trackerhits
          durationhits
          alerthits
          agenthits
          sentimenthits
          topichits
          questionhits
          analyticshits
          trackers
          type
          groups
          createdAt
          updatedAt
          companyCampaignsId
        }
        nextToken
      }
      agents {
        items {
          id
          name
          groups
          createdAt
          updatedAt
          companyAgentsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      name
      contact
      email
      status
      usage
      internalname
      groups
      VShits
      trackerhits
      durationhits
      alerthits
      agenthits
      sentimenthits
      topichits
      questionhits
      analyticshits
      alerts {
        items {
          id
          name
          agent
          conversation
          campaign
          groups
          createdAt
          updatedAt
          companyAlertsId
        }
        nextToken
      }
      trackers
      campaigns {
        items {
          id
          name
          startdate
          enddate
          active
          pinned
          VShits
          trackerhits
          durationhits
          alerthits
          agenthits
          sentimenthits
          topichits
          questionhits
          analyticshits
          trackers
          type
          groups
          createdAt
          updatedAt
          companyCampaignsId
        }
        nextToken
      }
      agents {
        items {
          id
          name
          groups
          createdAt
          updatedAt
          companyAgentsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      firstname
      lastname
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      firstname
      lastname
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      firstname
      lastname
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createAlert = /* GraphQL */ `
  mutation CreateAlert(
    $input: CreateAlertInput!
    $condition: ModelAlertConditionInput
  ) {
    createAlert(input: $input, condition: $condition) {
      id
      name
      agent
      conversation
      campaign
      company {
        id
        name
        contact
        email
        status
        usage
        internalname
        groups
        VShits
        trackerhits
        durationhits
        alerthits
        agenthits
        sentimenthits
        topichits
        questionhits
        analyticshits
        alerts {
          nextToken
        }
        trackers
        campaigns {
          nextToken
        }
        agents {
          nextToken
        }
        createdAt
        updatedAt
      }
      groups
      createdAt
      updatedAt
      companyAlertsId
    }
  }
`;
export const updateAlert = /* GraphQL */ `
  mutation UpdateAlert(
    $input: UpdateAlertInput!
    $condition: ModelAlertConditionInput
  ) {
    updateAlert(input: $input, condition: $condition) {
      id
      name
      agent
      conversation
      campaign
      company {
        id
        name
        contact
        email
        status
        usage
        internalname
        groups
        VShits
        trackerhits
        durationhits
        alerthits
        agenthits
        sentimenthits
        topichits
        questionhits
        analyticshits
        alerts {
          nextToken
        }
        trackers
        campaigns {
          nextToken
        }
        agents {
          nextToken
        }
        createdAt
        updatedAt
      }
      groups
      createdAt
      updatedAt
      companyAlertsId
    }
  }
`;
export const deleteAlert = /* GraphQL */ `
  mutation DeleteAlert(
    $input: DeleteAlertInput!
    $condition: ModelAlertConditionInput
  ) {
    deleteAlert(input: $input, condition: $condition) {
      id
      name
      agent
      conversation
      campaign
      company {
        id
        name
        contact
        email
        status
        usage
        internalname
        groups
        VShits
        trackerhits
        durationhits
        alerthits
        agenthits
        sentimenthits
        topichits
        questionhits
        analyticshits
        alerts {
          nextToken
        }
        trackers
        campaigns {
          nextToken
        }
        agents {
          nextToken
        }
        createdAt
        updatedAt
      }
      groups
      createdAt
      updatedAt
      companyAlertsId
    }
  }
`;
export const createAgent = /* GraphQL */ `
  mutation CreateAgent(
    $input: CreateAgentInput!
    $condition: ModelAgentConditionInput
  ) {
    createAgent(input: $input, condition: $condition) {
      id
      name
      company {
        id
        name
        contact
        email
        status
        usage
        internalname
        groups
        VShits
        trackerhits
        durationhits
        alerthits
        agenthits
        sentimenthits
        topichits
        questionhits
        analyticshits
        alerts {
          nextToken
        }
        trackers
        campaigns {
          nextToken
        }
        agents {
          nextToken
        }
        createdAt
        updatedAt
      }
      groups
      campaigns {
        items {
          id
          agentID
          campaignID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      companyAgentsId
    }
  }
`;
export const updateAgent = /* GraphQL */ `
  mutation UpdateAgent(
    $input: UpdateAgentInput!
    $condition: ModelAgentConditionInput
  ) {
    updateAgent(input: $input, condition: $condition) {
      id
      name
      company {
        id
        name
        contact
        email
        status
        usage
        internalname
        groups
        VShits
        trackerhits
        durationhits
        alerthits
        agenthits
        sentimenthits
        topichits
        questionhits
        analyticshits
        alerts {
          nextToken
        }
        trackers
        campaigns {
          nextToken
        }
        agents {
          nextToken
        }
        createdAt
        updatedAt
      }
      groups
      campaigns {
        items {
          id
          agentID
          campaignID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      companyAgentsId
    }
  }
`;
export const deleteAgent = /* GraphQL */ `
  mutation DeleteAgent(
    $input: DeleteAgentInput!
    $condition: ModelAgentConditionInput
  ) {
    deleteAgent(input: $input, condition: $condition) {
      id
      name
      company {
        id
        name
        contact
        email
        status
        usage
        internalname
        groups
        VShits
        trackerhits
        durationhits
        alerthits
        agenthits
        sentimenthits
        topichits
        questionhits
        analyticshits
        alerts {
          nextToken
        }
        trackers
        campaigns {
          nextToken
        }
        agents {
          nextToken
        }
        createdAt
        updatedAt
      }
      groups
      campaigns {
        items {
          id
          agentID
          campaignID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      companyAgentsId
    }
  }
`;
export const createCampaign = /* GraphQL */ `
  mutation CreateCampaign(
    $input: CreateCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    createCampaign(input: $input, condition: $condition) {
      id
      name
      startdate
      enddate
      active
      pinned
      VShits
      trackerhits
      durationhits
      alerthits
      agenthits
      sentimenthits
      topichits
      questionhits
      analyticshits
      trackers
      type
      company {
        id
        name
        contact
        email
        status
        usage
        internalname
        groups
        VShits
        trackerhits
        durationhits
        alerthits
        agenthits
        sentimenthits
        topichits
        questionhits
        analyticshits
        alerts {
          nextToken
        }
        trackers
        campaigns {
          nextToken
        }
        agents {
          nextToken
        }
        createdAt
        updatedAt
      }
      conversations {
        items {
          id
          name
          agent
          agenttag
          archive
          startdate
          sentiment
          enddate
          bookmarks
          duration
          VS
          symblstatus
          symbljobid
          symblconversationid
          alertcount
          transcript
          topics
          analytics
          questions
          summary
          trackersdetected
          file
          groups
          createdAt
          updatedAt
          campaignConversationsId
        }
        nextToken
      }
      agents {
        items {
          id
          agentID
          campaignID
          createdAt
          updatedAt
        }
        nextToken
      }
      groups
      createdAt
      updatedAt
      companyCampaignsId
    }
  }
`;
export const updateCampaign = /* GraphQL */ `
  mutation UpdateCampaign(
    $input: UpdateCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    updateCampaign(input: $input, condition: $condition) {
      id
      name
      startdate
      enddate
      active
      pinned
      VShits
      trackerhits
      durationhits
      alerthits
      agenthits
      sentimenthits
      topichits
      questionhits
      analyticshits
      trackers
      type
      company {
        id
        name
        contact
        email
        status
        usage
        internalname
        groups
        VShits
        trackerhits
        durationhits
        alerthits
        agenthits
        sentimenthits
        topichits
        questionhits
        analyticshits
        alerts {
          nextToken
        }
        trackers
        campaigns {
          nextToken
        }
        agents {
          nextToken
        }
        createdAt
        updatedAt
      }
      conversations {
        items {
          id
          name
          agent
          agenttag
          archive
          startdate
          sentiment
          enddate
          bookmarks
          duration
          VS
          symblstatus
          symbljobid
          symblconversationid
          alertcount
          transcript
          topics
          analytics
          questions
          summary
          trackersdetected
          file
          groups
          createdAt
          updatedAt
          campaignConversationsId
        }
        nextToken
      }
      agents {
        items {
          id
          agentID
          campaignID
          createdAt
          updatedAt
        }
        nextToken
      }
      groups
      createdAt
      updatedAt
      companyCampaignsId
    }
  }
`;
export const deleteCampaign = /* GraphQL */ `
  mutation DeleteCampaign(
    $input: DeleteCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    deleteCampaign(input: $input, condition: $condition) {
      id
      name
      startdate
      enddate
      active
      pinned
      VShits
      trackerhits
      durationhits
      alerthits
      agenthits
      sentimenthits
      topichits
      questionhits
      analyticshits
      trackers
      type
      company {
        id
        name
        contact
        email
        status
        usage
        internalname
        groups
        VShits
        trackerhits
        durationhits
        alerthits
        agenthits
        sentimenthits
        topichits
        questionhits
        analyticshits
        alerts {
          nextToken
        }
        trackers
        campaigns {
          nextToken
        }
        agents {
          nextToken
        }
        createdAt
        updatedAt
      }
      conversations {
        items {
          id
          name
          agent
          agenttag
          archive
          startdate
          sentiment
          enddate
          bookmarks
          duration
          VS
          symblstatus
          symbljobid
          symblconversationid
          alertcount
          transcript
          topics
          analytics
          questions
          summary
          trackersdetected
          file
          groups
          createdAt
          updatedAt
          campaignConversationsId
        }
        nextToken
      }
      agents {
        items {
          id
          agentID
          campaignID
          createdAt
          updatedAt
        }
        nextToken
      }
      groups
      createdAt
      updatedAt
      companyCampaignsId
    }
  }
`;
export const createConversation = /* GraphQL */ `
  mutation CreateConversation(
    $input: CreateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    createConversation(input: $input, condition: $condition) {
      id
      name
      agent
      agenttag
      archive
      startdate
      sentiment
      enddate
      bookmarks
      duration
      VS
      symblstatus
      symbljobid
      symblconversationid
      alertcount
      transcript
      topics
      analytics
      questions
      summary
      trackersdetected
      file
      campaign {
        id
        name
        startdate
        enddate
        active
        pinned
        VShits
        trackerhits
        durationhits
        alerthits
        agenthits
        sentimenthits
        topichits
        questionhits
        analyticshits
        trackers
        type
        company {
          id
          name
          contact
          email
          status
          usage
          internalname
          groups
          VShits
          trackerhits
          durationhits
          alerthits
          agenthits
          sentimenthits
          topichits
          questionhits
          analyticshits
          trackers
          createdAt
          updatedAt
        }
        conversations {
          nextToken
        }
        agents {
          nextToken
        }
        groups
        createdAt
        updatedAt
        companyCampaignsId
      }
      groups
      createdAt
      updatedAt
      campaignConversationsId
    }
  }
`;
export const updateConversation = /* GraphQL */ `
  mutation UpdateConversation(
    $input: UpdateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    updateConversation(input: $input, condition: $condition) {
      id
      name
      agent
      agenttag
      archive
      startdate
      sentiment
      enddate
      bookmarks
      duration
      VS
      symblstatus
      symbljobid
      symblconversationid
      alertcount
      transcript
      topics
      analytics
      questions
      summary
      trackersdetected
      file
      campaign {
        id
        name
        startdate
        enddate
        active
        pinned
        VShits
        trackerhits
        durationhits
        alerthits
        agenthits
        sentimenthits
        topichits
        questionhits
        analyticshits
        trackers
        type
        company {
          id
          name
          contact
          email
          status
          usage
          internalname
          groups
          VShits
          trackerhits
          durationhits
          alerthits
          agenthits
          sentimenthits
          topichits
          questionhits
          analyticshits
          trackers
          createdAt
          updatedAt
        }
        conversations {
          nextToken
        }
        agents {
          nextToken
        }
        groups
        createdAt
        updatedAt
        companyCampaignsId
      }
      groups
      createdAt
      updatedAt
      campaignConversationsId
    }
  }
`;
export const deleteConversation = /* GraphQL */ `
  mutation DeleteConversation(
    $input: DeleteConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    deleteConversation(input: $input, condition: $condition) {
      id
      name
      agent
      agenttag
      archive
      startdate
      sentiment
      enddate
      bookmarks
      duration
      VS
      symblstatus
      symbljobid
      symblconversationid
      alertcount
      transcript
      topics
      analytics
      questions
      summary
      trackersdetected
      file
      campaign {
        id
        name
        startdate
        enddate
        active
        pinned
        VShits
        trackerhits
        durationhits
        alerthits
        agenthits
        sentimenthits
        topichits
        questionhits
        analyticshits
        trackers
        type
        company {
          id
          name
          contact
          email
          status
          usage
          internalname
          groups
          VShits
          trackerhits
          durationhits
          alerthits
          agenthits
          sentimenthits
          topichits
          questionhits
          analyticshits
          trackers
          createdAt
          updatedAt
        }
        conversations {
          nextToken
        }
        agents {
          nextToken
        }
        groups
        createdAt
        updatedAt
        companyCampaignsId
      }
      groups
      createdAt
      updatedAt
      campaignConversationsId
    }
  }
`;
export const createAgentsandcampaigns = /* GraphQL */ `
  mutation CreateAgentsandcampaigns(
    $input: CreateAgentsandcampaignsInput!
    $condition: ModelAgentsandcampaignsConditionInput
  ) {
    createAgentsandcampaigns(input: $input, condition: $condition) {
      id
      agentID
      campaignID
      agent {
        id
        name
        company {
          id
          name
          contact
          email
          status
          usage
          internalname
          groups
          VShits
          trackerhits
          durationhits
          alerthits
          agenthits
          sentimenthits
          topichits
          questionhits
          analyticshits
          trackers
          createdAt
          updatedAt
        }
        groups
        campaigns {
          nextToken
        }
        createdAt
        updatedAt
        companyAgentsId
      }
      campaign {
        id
        name
        startdate
        enddate
        active
        pinned
        VShits
        trackerhits
        durationhits
        alerthits
        agenthits
        sentimenthits
        topichits
        questionhits
        analyticshits
        trackers
        type
        company {
          id
          name
          contact
          email
          status
          usage
          internalname
          groups
          VShits
          trackerhits
          durationhits
          alerthits
          agenthits
          sentimenthits
          topichits
          questionhits
          analyticshits
          trackers
          createdAt
          updatedAt
        }
        conversations {
          nextToken
        }
        agents {
          nextToken
        }
        groups
        createdAt
        updatedAt
        companyCampaignsId
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAgentsandcampaigns = /* GraphQL */ `
  mutation UpdateAgentsandcampaigns(
    $input: UpdateAgentsandcampaignsInput!
    $condition: ModelAgentsandcampaignsConditionInput
  ) {
    updateAgentsandcampaigns(input: $input, condition: $condition) {
      id
      agentID
      campaignID
      agent {
        id
        name
        company {
          id
          name
          contact
          email
          status
          usage
          internalname
          groups
          VShits
          trackerhits
          durationhits
          alerthits
          agenthits
          sentimenthits
          topichits
          questionhits
          analyticshits
          trackers
          createdAt
          updatedAt
        }
        groups
        campaigns {
          nextToken
        }
        createdAt
        updatedAt
        companyAgentsId
      }
      campaign {
        id
        name
        startdate
        enddate
        active
        pinned
        VShits
        trackerhits
        durationhits
        alerthits
        agenthits
        sentimenthits
        topichits
        questionhits
        analyticshits
        trackers
        type
        company {
          id
          name
          contact
          email
          status
          usage
          internalname
          groups
          VShits
          trackerhits
          durationhits
          alerthits
          agenthits
          sentimenthits
          topichits
          questionhits
          analyticshits
          trackers
          createdAt
          updatedAt
        }
        conversations {
          nextToken
        }
        agents {
          nextToken
        }
        groups
        createdAt
        updatedAt
        companyCampaignsId
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAgentsandcampaigns = /* GraphQL */ `
  mutation DeleteAgentsandcampaigns(
    $input: DeleteAgentsandcampaignsInput!
    $condition: ModelAgentsandcampaignsConditionInput
  ) {
    deleteAgentsandcampaigns(input: $input, condition: $condition) {
      id
      agentID
      campaignID
      agent {
        id
        name
        company {
          id
          name
          contact
          email
          status
          usage
          internalname
          groups
          VShits
          trackerhits
          durationhits
          alerthits
          agenthits
          sentimenthits
          topichits
          questionhits
          analyticshits
          trackers
          createdAt
          updatedAt
        }
        groups
        campaigns {
          nextToken
        }
        createdAt
        updatedAt
        companyAgentsId
      }
      campaign {
        id
        name
        startdate
        enddate
        active
        pinned
        VShits
        trackerhits
        durationhits
        alerthits
        agenthits
        sentimenthits
        topichits
        questionhits
        analyticshits
        trackers
        type
        company {
          id
          name
          contact
          email
          status
          usage
          internalname
          groups
          VShits
          trackerhits
          durationhits
          alerthits
          agenthits
          sentimenthits
          topichits
          questionhits
          analyticshits
          trackers
          createdAt
          updatedAt
        }
        conversations {
          nextToken
        }
        agents {
          nextToken
        }
        groups
        createdAt
        updatedAt
        companyCampaignsId
      }
      createdAt
      updatedAt
    }
  }
`;
