import React, { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { TextField, Button, Box, Typography } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const DatePickerComponent = ({ onDateChange = () => {} }) => {
  const currentDate = dayjs();
  const [fromDate, setFromDate] = useState(currentDate);
  const [toDate, setToDate] = useState(currentDate);
  const [tempFromDate, setTempFromDate] = useState(currentDate);
  const [tempToDate, setTempToDate] = useState(currentDate);

  const handleTempFromDateChange = (date) => {
    setTempFromDate(date);
  };

  const handleTempToDateChange = (date) => {
    setTempToDate(date);
  };

  const handleApply = () => {
    setFromDate(tempFromDate);
    setToDate(tempToDate);
    onDateChange({
      from: tempFromDate ? dayjs(tempFromDate).format("YYYY-MM") : "",
      to: tempToDate ? dayjs(tempToDate).format("YYYY-MM") : "",
    });
  };

  const handleReset = () => {
    setTempFromDate(currentDate);
    setTempToDate(currentDate);
    setFromDate(currentDate);
    setToDate(currentDate);
    onDateChange({
      from: currentDate.format("YYYY-MM"),
      to: currentDate.format("YYYY-MM"),
    });
  };

  return (
    <Box
      sx={{
        width: "20%",
        backgroundColor: "#fff",
        padding: 2,
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Typography variant="h6" color="textSecondary">
        DATE RANGE
      </Typography>

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label="From Date"
          value={tempFromDate}
          onChange={handleTempFromDateChange}
          views={["year", "month"]}
          InputLabelProps={{ style: { color: "#84858a" } }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: tempFromDate ? "inherit" : "#df9508",
                  },
                  "&:hover fieldset": {
                    borderColor: tempFromDate ? "inherit" : "#df9508",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#df9508",
                  },
                },
              }}
            />
          )}
        />

        <DatePicker
          label="To Date"
          value={tempToDate}
          onChange={handleTempToDateChange}
          views={["year", "month"]}
          InputLabelProps={{ style: { color: "#84858a" } }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: tempToDate ? "inherit" : "#df9508",
                  },
                  "&:hover fieldset": {
                    borderColor: tempToDate ? "inherit" : "#df9508",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#df9508",
                  },
                },
              }}
            />
          )}
        />
      </LocalizationProvider>

      <Box sx={{ display: "flex", gap: 2 }}>
        <Button
          variant="contained"
          onClick={handleReset}
          style={{ backgroundColor: "#df9508" }}
        >
          Reset
        </Button>
        <Button
          variant="contained"
          onClick={handleApply}
          style={{ backgroundColor: "#df9508" }}
        >
          Apply
        </Button>
      </Box>
    </Box>
  );
};

export default DatePickerComponent;
