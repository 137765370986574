import React from "react";
import { useDropzone } from "react-dropzone";

const FileDropZone = ({ files, setFiles }) => {
  const onDrop = (acceptedFiles) => {
    setFiles(acceptedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    noClick: false,
    noKeyboard: false// Allow multiple files
  });

  return (
    <div
      {...getRootProps()}
      style={{
        border: "2px dashed #ccc",
        borderRadius: "8px",
        padding: "20px",
        textAlign: "center",
        cursor: "pointer", // Makes it clear that the area is clickable
      }}
    >
      <input {...getInputProps()} />
      {files.length > 0 ? (
        <ul>
          {files.map((file, index) => (
            <li key={index} > {/* Text color set to black */}
              {file.name}
            </li>
          ))}
        </ul>
      ) : (
        <p style={{ color: "#000" }}> {/* Text color set to black */}
          Drag and drop files here, or click to select files
        </p>
      )}
    </div>
  );
};

export default FileDropZone;